body {margin:0;padding:0}
.header {
  background-color: #e9f8fd;
  padding: 20px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  margin-right: 10px;
}

.nav {
  display: flex;
  align-items: center;
}

.nav-list {
  list-style: none;
  display: flex;
  padding: 0;
  margin: 0;
}

 

.nav-item {
  margin: 0 15px;
}

.nav-link {
  color: #333333ab;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease;
  position: relative;
}
 
.nav-link:hover {
  color: #0078FF;
}

.nav-link:hover::before {
  color: #0078FF;
}

.contact-button {
  padding: 15px 30px;
  border-radius: 25px;
  border: none;
  background-color: #000;
  color: #fff;
  cursor: pointer;
  font-size: 16px;
  display: flex;
  align-items: center;
  position: relative;
  overflow: hidden;
}

.contact-button::after {
  content: '↗';
  position: absolute;
  right: 20px;
  background-color: #FF5252;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  color: #fff;
}

.contact-button:hover {
  background-color: #333;
}

.footer-link {
  color: #333;
  text-decoration: none;
  margin-bottom: 5px;
  font-size: 14px;
  transition: color 0.3s ease;
  display: block;
  position: relative;
}

.footer-link::before {
  content: '↗';
  position: absolute;
  left: -20px; /* Ajuste conforme necessário para a posição correta */
  top: 0;
  font-size: 14px;
  color: #333;
  transition: color 0.3s ease;
}

.footer-link:hover {
  color: #0078FF;
}

.footer-link:hover::before {
  color: #0078FF;
}

 
.nav-list {
  display: flex;
  flex-direction: row;
}

.nav-list.open {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.hamburger {
  display: none;
}

@media (max-width: 768px) {
  .nav-list {
      display: none;
  }

  .nav-list.open {
      display: flex;
  }

  .hamburger {
      display: block;
  }
}

/* styles.css */
.hide-scrollbar {
  overflow: hidden; /* Oculta a barra de rolagem */
}

.hide-scrollbar::-webkit-scrollbar {
  display: none; /* Oculta a barra de rolagem no Webkit (Chrome, Safari) */
}
