.video-section {
   font-family: Roboto, sans-serif;
    padding: 50px 0;
    text-align: center;
    padding: 12px;
  }
  
  .video-section h1 {
    font-size: 2.5em;
    margin-bottom: 10px;
  }
  
  .video-section p {
    font-size: 1.2em;
    margin-bottom: 20px;
  }
  
  .video-container {
    margin: 20px 0;
  }
  
  video {
    width: 100%;
    height: auto;
    max-width: 800px;
  }
  
  .cta-buttons {
    margin-top: 20px;
  }
  
  .button {
    background: #007BFF;
    color: white;
    padding: 10px 20px;
    text-decoration: none;
    margin: 5px;
    display: inline-block;
    border-radius: 5px;
  }
  
  .button:hover {
    background: #0056b3;
  }
  